import React, { useState, useEffect } from "react"

import fetchJsonp from "fetch-jsonp"
import SmartFeedJobAlerts from "./SmartFeedJobAlerts"

import { Link } from "gatsby"

import "url-search-params-polyfill"

require("es6-promise").polyfill()

let prevCount = 0
let currentCount = 0
let keywordTime = null

const setVacancyLoadingBackup = function setVacancyLoadingBackup(
  loading,
  vacancyLoading
) {
  if (loading) {
    vacancyLoading = true
    return true
  }

  vacancyLoading = false
  return false
}

const SmartFeedFiltersComponent = ({
  vacancyLoading = true,
  apiKey = "",
  gdprKey = "",
  group = false,
  CompanyGroupName = "",
  VacancyPageBaseURL = "",
  ReturnToSearchURL = "",
  entireLocationsList = [],
  contentDataData = [],
  setVacancyLoading = setVacancyLoadingBackup,
}) => {
  const id = 1
  const [vacancy, setVacancy] = useState([])
  const [vacancyTitle, setVacancyTitle] = useState("Loading vacancies...")
  const [isIinitialLoad, setIsIinitialLoad] = useState(true)
  const [initialLoading, setInitialLoading] = useState(true)

  // Filter States
  const [locationFilter, setLocationFilter] = useState([])
  const [sublocationFilter, setSubLocationFilter] = useState([])
  const [regionFilter, setRegionFilter] = useState([])
  const [categoryFilter, setCategoryFilter] = useState([])
  const [industryFilter, setIndustryFilter] = useState([])
  const [postingTypeFilter, setPostingTypeFilter] = useState([])
  const [jobTypeFilter, setJobTypeFilter] = useState([])
  const [keywordFilter, setKeywordFilter] = useState([])
  const [autoCompleteFilter, setAutoCompleteFilter] = useState([])
  const [autocompleteListHide, setAutocompleteListHide] = useState(true)
  const [filterState, setFilterState] = useState({})
  const [vacsCount, setVacsCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [loadMoreText, setLoadMoreText] = useState(false)

  // Button filters
  const [careersNavState, setCareersNavState] = useState("careers")

  const addActiveClassnameToNav = function addActiveClassnameToNav(stateName) {
    if (stateName === careersNavState) {
      return "VF_Nav_Item_Active"
    }

    return ""
  }

  let groupOrIdParam = "id"

  if (group) {
    groupOrIdParam = "group"
  }

  function removeDuplicates(array) {
    return array.filter((a, b) => array.indexOf(a) === b)
  }

  const fetchCustomFilter = async (setState, label) => {
    // let temp = ["All " + label]
    let temp = ["All " + label]

    let jsonList = []

    entireLocationsList.map(async (location, i) => {
      jsonList.push(location.title)
    })

    setState(temp.concat(removeDuplicates(jsonList)))

    setVacancyLoading(false)
  }

  const fetchFilters = async (name, setState, label) => {
    try {
      fetchJsonp(
        "https://sf.vacancy-filler.co.uk/CareerPage/" +
          name +
          "?" +
          groupOrIdParam +
          "=" +
          apiKey
      )
        .then(function(response) {
          return response.json()
        })
        .then(function(jsonList) {
          // let temp = ["All " + label]
          let temp = ["All " + label]

          setState(temp.concat(removeDuplicates(jsonList)))

          setVacancyLoading(false)
        })
        .catch(function(ex) {
          setLoading(false)
          setVacancyLoading(false)
          console.error(ex, "error")
        })
    } catch (error) {
      setLoading(false)
      setVacancyLoading(false)
      console.error(error.message, "error")
    }
  }

  const fetchVacancies = async (
    state,
    name,
    e,
    loadMore,
    postingTypeSelection
  ) => {
    let postingTypeSelectionValue = careersNavState

    if (careersNavState !== postingTypeSelection && postingTypeSelection) {
      postingTypeSelectionValue = postingTypeSelection
    }

    // State of filters, Name of searched filter, event, load more boolean
    setLoadMoreText("Loading.....")

    if (name) {
      prevCount = 0
      currentCount = 0
    }

    let temp = state

    if (name) {
      if (e) {
        if (e.target && e.target.value.includes(name)) {
          e.target.value = ""
        }
      }

      if (name === "autocomplete") {
        temp.latitude = e.lat
        temp.longitude = e.long
        temp.distance = e.distance
        temp.unit = "Miles"
      } else if (name === "sublocation") {
        if (e.target.value.includes("All ")) {
          delete temp.sublocation
        } else {
          temp.sublocation = e.target.value
        }
      } else if (name === "location") {
        if (e.target.value.includes("All ")) {
          delete temp.location
        } else {
          temp.location = e.target.value
        }
      } else if (name === "keywords") {
        temp.keywords = e.target.value
      } else if (name === "region") {
        if (e.target.value.includes("All ")) {
          delete temp.region
        } else {
          temp.region = e.target.value
        }
      } else if (name === "category") {
        if (e.target.value.includes("All ")) {
          delete temp.category
        } else {
          temp.category = e.target.value
        }
      } else if (name === "industry") {
        if (e.target.value.includes("All ")) {
          delete temp.industrysector
        } else {
          temp.industrysector = e.target.value
        }
      } else if (name === "postingType") {
        if (e.target.value.includes("All ")) {
          delete temp.postingtype
        } else {
          temp.postingtype = e.target.value
        }
      } else if (name === "jobtype") {
        if (e.target.value.includes("All ")) {
          delete temp.jobtype
        } else {
          temp.jobtype = e.target.value
        }
      } else if (name === "jobtime") {
        if (e.target.value.includes("All ")) {
          delete temp.jobtime
        } else {
          temp.jobtime = e.target.value
        }
      } else if (name === "country") {
        if (e.target.value.includes("All ")) {
          delete temp.country
        } else {
          temp.country = e.target.value
        }
      } else if (name === "companySearch") {
        if (e.target.value.includes("All ")) {
          delete temp.CompanySearch
        } else {
          temp.CompanySearch = e.target.value
        }
      } else if (name === "reset") {
        delete temp.CompanySearch
        delete temp.country
        delete temp.jobtime
        delete temp.jobtype
        delete temp.postingtype
        delete temp.industrysector
        delete temp.category
        delete temp.region
        delete temp.latitude
        delete temp.longitude
        delete temp.distance
        delete temp.unit

        if (document && document.getElementById("Keywords")) {
          document.getElementById("Keywords").value = ""
        }
      }

      setFilterState(temp)
    }

    function getAdvertData(jobs) {
      return Promise.all(
        jobs.slice(prevCount, currentCount).map(async id => {
          return fetchJsonp(
            "https://sf.vacancy-filler.co.uk/CareerPage/GetItem?id=" + id
          ).then(function(response) {
            return response.json()
          })
        })
      )
    }

    if (!loadMore) {
      prevCount = 0
      currentCount = 0
      setLoading(true)
    }

    try {
      fetchJsonp(
        "https://sf.vacancy-filler.co.uk/CareerPage/GetResultList?id=" +
          apiKey +
          "&" +
          "sortby=postingdate" +
          "&" +
          "sortdir=desc" +
          "&" +
          "postingtype=" +
          postingTypeSelectionValue +
          "&" +
          buildQuery(temp)
      )
        .then(function(response) {
          return response.json()
        })
        .then(async function(jsonList) {
          prevCount = currentCount
          currentCount = currentCount + 12

          await getAdvertData(jsonList).then(function(data) {
            if (loadMore) {
              setVacancy(vacancy.concat(data))
            } else {
              setVacancy(data)
            }

            if (jsonList.length === 0) {
              setVacancyTitle(
                "We couldn't find any vacancies matching your search"
              )
            } else if (jsonList.length === 0) {
              setVacancyTitle("We have " + jsonList.length + " vacancy")
            } else {
              setVacancyTitle("We have " + jsonList.length + " vacancies")
            }

            setInitialLoading(false)

            setLoading(false)
            setVacancyLoading(false)

            setLoadMoreText("Load More Roles")
            setVacsCount(jsonList.length)
          })
        })
    } catch (error) {
      setLoading(false)
      setVacancyLoading(false)
      console.error(error.message, "error")
    }
  }

  const chooseGeoLocation = function chooseGeoLocation(lat, long, distance) {
    setAutocompleteListHide(true)
    fetchVacancies(
      filterState,
      "autocomplete",
      { lat: lat, long: long, distance: distance },
      false
    )
  }

  const renderFilter = function renderFilter(data, label, stateName) {
    if (label === "Keyword Search") {
      return (
        <div className="vf-filter">
          <label aria-label={label} htmlFor={"select-" + label}>
            {label}:{" "}
          </label>
          <input
            defaultValue={filterState[stateName]}
            onChange={e => {
              e.persist()
              keywordSearchChange(filterState, stateName, e, false)
            }}
            className="VF-form-control onchange-search form-control"
            id={"select-" + label}
            name={label}
            placeholder="Enter keywords"
            type="text"
            aria-labelledby="keywordSearchLabel"
            aria-describedby="Enter keywords"
          />
        </div>
      )
    } else if (label === "Search Location") {
      return (
        <div className="vf-filter">
          <label aria-label={label} htmlFor={"select-" + label}>
            {label}:{" "}
          </label>
          <div className="vf-filter-loc-complete-wrap">
            <input
              value={filterState[stateName]}
              onChange={e =>
                autocompleteSearchChange(filterState, label, e, false)
              }
              className="VF-form-control onchange-search form-control"
              id="Keywords"
              name="Keywords"
              placeholder="Enter postcode, town or city"
              type="text"
              aria-labelledby="keywordSearchLabel"
              aria-describedby="Enter keywords"
            />
            {!autocompleteListHide &&
              data &&
              Array.isArray(data) &&
              data.length > 0 && (
                <div className="vf-filter-loc-complete-list">
                  {data.map((loc, i) => (
                    <div
                      key={i}
                      onClick={() =>
                        chooseGeoLocation(loc.latitude, loc.longitude, 10)
                      }
                    >
                      {loc.displayName}
                    </div>
                  ))}
                </div>
              )}
          </div>
        </div>
      )
    } else {
      return (
        <div className="vf-filter">
          <label aria-label={label} htmlFor={"select-" + stateName}>
            {label}:{" "}
          </label>
          <select
            className="form-control"
            value={filterState[stateName] || data[0]}
            onChange={e => fetchVacancies(filterState, stateName, e, false)}
            name={"select-" + stateName}
            id={"select-" + stateName}
          >
            {data.map((item, i) => (
              <option key={i} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
      )
    }
  }

  const clickCurrentVacancies = function clickCurrentVacancies() {
    setCareersNavState("careers")
    fetchVacancies(filterState, undefined, undefined, false, "careers")
  }

  const clickRegisterVacancies = function clickRegisterVacancies() {
    setCareersNavState("register")
    fetchVacancies(filterState, undefined, undefined, false, "register")
  }

  const clickAlertsVacancies = function clickAlertsVacancies() {
    setCareersNavState("alerts")
  }

  const loadMore = function loadMore() {
    fetchVacancies(filterState, undefined, undefined, true)
  }

  const resetVacs = function resetVacs() {
    fetchVacancies({}, "reset", undefined, false)
  }

  const searchVacs = function searchVacs() {
    fetchVacancies({}, "search", undefined, false)
  }

  const autocompleteSearchChange = function autocompleteSearchChange(
    state,
    name,
    e,
    loadMore
  ) {
    setAutocompleteListHide(false)

    let val = e.currentTarget.value

    if (e.currentTarget.value.length > 3) {
      // Do api call to get key
      fetchJsonp(
        "https://sf.vacancy-filler.co.uk/CareerPage/GetLocationAPIKey?id=" +
          apiKey
      )
        .then(function(response) {
          return response.json()
        })
        .then(function(geoKey) {
          // Do api call to get locs
          fetch(
            "https://geocoding.vacancy-filler.co.uk/api/V2/exact/?apikey=" +
              geoKey.key +
              "&search=" +
              encodeURIComponent(val)
          )
            .then(res => res.json())
            .then(result => {
              setAutoCompleteFilter(result.locationResults)
            })
        })
    }
  }

  const keywordSearchChange = function keywordSearchChange(
    state,
    name,
    e,
    loadMore
  ) {
    clearTimeout(keywordTime)

    keywordTime = setTimeout(() => {
      fetchVacancies(state, name, e, loadMore)
    }, 500)
  }

  const getSummary = function getSummary(summary, description) {
    if (summary) {
      return summary
    } else {
      let alteredDescription = description.replace(/<[^>]*>?/gm, "")
      let tempSummary = alteredDescription.substring(0, 397)
      if (tempSummary.length === 397) {
        tempSummary += "..."
      }
      return tempSummary
    }
  }

  const calculateSalary = function calculateSalary(
    Currency,
    SalaryFrom,
    SalaryTo,
    SalaryType,
    SalaryPeriod
  ) {
    function numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    let NewSalary
    let NewSalaryFrom
    let NewSalaryTo

    let currencyFormatted = Currency

    if (Currency === "&pound;") {
      currencyFormatted = "£"
    }

    if (SalaryTo === 0) {
      NewSalary = SalaryType
    } else if (SalaryFrom === SalaryTo) {
      if (SalaryFrom >= 1000) {
        // Remove decimals
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed())
      } else {
        // Add two decimals
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed(2))
      }
      NewSalary = NewSalaryFrom + " " + SalaryPeriod
    } else {
      if (SalaryFrom >= 1000) {
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed())
      } else {
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed(2))
      }
      if (SalaryTo >= 1000) {
        NewSalaryTo = currencyFormatted + numberWithCommas(SalaryTo.toFixed())
      } else {
        NewSalaryTo = currencyFormatted + numberWithCommas(SalaryTo.toFixed(2))
      }

      NewSalary = NewSalaryFrom + " - " + NewSalaryTo + " " + SalaryPeriod
    }
    return NewSalary
  }
  const getImageFromRegionCards = function getImageFromRegionCards(region) {
    let jobCardRegionNoSpaces = region.replace(/[, ]+/g, "-")

    if (jobCardRegionNoSpaces === "Cheddar-Gorge-&-Caves") {
      return "Cheddar-Gorge-and-Caves"
    }

    return jobCardRegionNoSpaces
  }

  const FormatDateTime = function FormatDateTime(JSONDate) {
    // Set default values to avoid function breaking
    var expDate = new Date(parseInt(JSONDate.substr(6)))
    var dateFormatOptions = new Object({})
    dateFormatOptions.weekday = "long"
    dateFormatOptions.year = "numeric"
    dateFormatOptions.month = "short"
    dateFormatOptions.day = "numeric"
    return expDate.toLocaleDateString(undefined, dateFormatOptions)
  }

  const buildQuery = function buildQuery(data) {
    if (typeof data === "string") return data
    let query = []

    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        query.push(
          encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
        )
      }
    }

    return query.join("&")
  }

  const slugify = function slugify(text) {
    return text
      .toString() // Cast to string
      .toLowerCase() // Convert the string to lowercase letters

      .trim() // Remove whitespace from both sides of a string
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
  }

  useEffect(() => {
    if (isIinitialLoad) {
      setIsIinitialLoad(false)
      if (URLSearchParams) {
        var urlParams = new URLSearchParams(window.location.search)
        var entries = urlParams.entries()
      }
      let temp = {}
      for (let pair of entries) {
        temp[pair[0]] = pair[1]
      }

      setFilterState(temp)

      const timeOut = setTimeout(() => {
        fetchVacancies(temp, undefined, undefined, false)
        fetchCustomFilter(setSubLocationFilter, "Care Homes")
        if (group) {
          fetchFilters("GetLocationListByGroup", setLocationFilter, "Locations") // Locations
          fetchFilters("GetRegionListByGroup", setLocationFilter, "Regions") // Regions
          fetchFilters(
            "GetCategoryListByGroup",
            setCategoryFilter,
            "Categories"
          ) // Categories
          fetchFilters(
            "GetIndustryListByGroup",
            setIndustryFilter,
            "Industries"
          ) // Industries
          fetchFilters(
            "GetPostingTypesListByGroup",
            setPostingTypeFilter,
            "Posting Types"
          ) // Posting Types
          fetchFilters("GetJobTypeListByGroup", setJobTypeFilter, "Job Types") // Job Types
        } else {
          fetchFilters("GetLocations", setLocationFilter, "Locations") // Locations
          fetchFilters("GetRegions", setRegionFilter, "Region") // Regions
          fetchFilters("GetCategories", setCategoryFilter, "Categories") // Categories
          fetchFilters("GetIndustries", setIndustryFilter, "Industries") // Industries
          //fetchFilters("GetPostingTypes", setPostingTypeFilter, "Posting Types") // Posting Types
          //fetchFilters("GetJobTypes", setJobTypeFilter, "Job Types") // Job Types
        }
      }, 50)
      return () => clearTimeout(timeOut)
    }
  }, [careersNavState])

  return (
    <div className={vacancyLoading ? "vacancies-landing-loading" : ""}>
      {!vacancyLoading ? (
        <>
          <div className="bannerWrapper">
            <div
              className="st-philips-banner-image"
              style={{
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundImage: `url(/images/vacancies-banner.jpg)`,
              }}
            ></div>
            <div className="bannerTextWrapper">
              <div className="bannerText">
                <div className="bannerContent">
                  <h1>Careers at St Philips Care</h1>
                  <p>
                    Here are all of our available role. Please view and search
                    for your new role below:
                  </p>
                </div>
              </div>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              class="hb-curve"
            >
              <polygon fill="#fff" points="0,100 100,0 100,100"></polygon>
            </svg>
          </div>

          <div className="vacancies-landing">
            <div className="container">
              <div className="row clearfix">
                <div className="col-md-12 column">
                  <div className="stphilips-spacing">
                    <div id="VF_vacancies">
                      {!vacancyLoading && (
                        <div className="row VF_vacancies_main_wrap">
                          <div className="col-12 col-lg-3">
                            <div className="search-filters-sidebar">
                              <h3 className="vacancies-landing__header__heading">
                                {vacancyTitle}
                              </h3>
                              <div className="page-search-input-group w-100 blog__listing__select keyword-search">
                                {renderFilter(
                                  keywordFilter,
                                  "Keyword Search",
                                  "keywords"
                                )}
                              </div>
                              {/* <div className="page-search-input-group w-100 blog__listing__select">
                                {renderFilter(
                                  sublocationFilter,
                                  "All Care Homes",
                                  "sublocation"
                                )}
                              </div> */}
                              <div className="page-search-input-group w-100 blog__listing__select">
                                {renderFilter(
                                  regionFilter,
                                  "All regions",
                                  "region"
                                )}
                              </div>
                              <div className="page-search-input-group w-100 blog__listing__select">
                                {renderFilter(
                                  locationFilter,
                                  "All locations",
                                  "location"
                                )}
                              </div>

                              <div className="page-search-input-group w-100 blog__listing__select">
                                {renderFilter(
                                  categoryFilter,
                                  "All categories",
                                  "category"
                                )}
                              </div>
                              <button
                                id="reset_vacs_search"
                                className="nav-action-sm"
                                onClick={() => resetVacs()}
                              >
                                Reset Search
                              </button>
                            </div>
                          </div>
                          <div className="col-12 col-lg-9">
                            {careersNavState !== "alerts" ? (
                              <div className="main-section">
                                <div className="row search-container-wrap">
                                  <div className="inner-content grid-section box-theme-2 joblist-section col-xs-12 col-12">
                                    <div
                                      id="VF-vacancies-wrapper"
                                      className="col-12 VF_vacancies_list"
                                    >
                                      <div className="row">
                                        {loading ? (
                                          "Loading vacancies..."
                                        ) : (
                                          <>
                                            {vacancy &&
                                            vacancy !== [] &&
                                            vacancy !== "[]" &&
                                            !initialLoading ? (
                                              <>
                                                {vacancy &&
                                                vacancy.length > 0 ? (
                                                  vacancy.map((job, i) => (
                                                    <div
                                                      className="col-12 col-lg-4 job-card-wrap"
                                                      id={new Buffer(
                                                        job.Id
                                                      ).toString("base64")}
                                                      key={i}
                                                    >
                                                      <Link
                                                        className="job-card card job-card-link"
                                                        to={
                                                          "/vacancies/vacancy/" +
                                                          new Buffer(
                                                            job.Id
                                                          ).toString("base64") +
                                                          "/" +
                                                          slugify(job.JobTitle)
                                                        }
                                                      >
                                                        <div className="job-card-details-wrap">
                                                          {job.SubLocation ? (
                                                            <div className="location-image">
                                                              <img
                                                                loading="lazy"
                                                                src={
                                                                  "https://cdn2.accropress.com/theme-assets/StPhilips/card-locations/" +
                                                                  slugify(
                                                                    job.SubLocation
                                                                  ) +
                                                                  ".jpg"
                                                                }
                                                                alt={
                                                                  job.SubLocation
                                                                }
                                                              />
                                                            </div>
                                                          ) : job.Location ? (
                                                            <div className="location-image">
                                                              <img
                                                                loading="lazy"
                                                                src={
                                                                  "https://cdn2.accropress.com/theme-assets/StPhilips/card-locations/" +
                                                                  slugify(
                                                                    job.Location
                                                                  ) +
                                                                  ".jpg"
                                                                }
                                                                alt={
                                                                  job.Location
                                                                }
                                                              />
                                                            </div>
                                                          ) : (
                                                            job.Region && (
                                                              <div className="location-image">
                                                                <img
                                                                  loading="lazy"
                                                                  src={
                                                                    "https://cdn2.accropress.com/theme-assets/StPhilips/card-locations/" +
                                                                    slugify(
                                                                      job.Region
                                                                    ) +
                                                                    ".jpg"
                                                                  }
                                                                  alt={
                                                                    job.Region
                                                                  }
                                                                />
                                                              </div>
                                                            )
                                                          )}
                                                          <div className="job-card-details">
                                                            <div className="row">
                                                              <div className="col-12">
                                                                <div className="job-card-header">
                                                                  <div className="job-card-title">
                                                                    {
                                                                      job.JobTitle
                                                                    }
                                                                  </div>
                                                                  <div className="job-card-location">
                                                                    {job.SubLocation &&
                                                                      job.SubLocation +
                                                                        ", "}
                                                                    {
                                                                      job.Location
                                                                    }
                                                                  </div>
                                                                  <div className="job-card-region">
                                                                    {job.Region}
                                                                  </div>
                                                                </div>
                                                                <div className="job-card-meta">
                                                                  <div className="job-card-meta-item">
                                                                    <span>
                                                                      <span class="fas fa-money-bill-wave"></span>
                                                                      Salary:
                                                                    </span>
                                                                    {calculateSalary(
                                                                      job.Currency,
                                                                      job.SalaryFrom,
                                                                      job.SalaryTo,
                                                                      job.SalaryType,
                                                                      job.SalaryPeriod
                                                                    )}
                                                                  </div>
                                                                  <div className="job-card-meta-item">
                                                                    <span>
                                                                      <span class="fas fa-hourglass-start"></span>
                                                                      Closing
                                                                      Date:
                                                                    </span>
                                                                    {FormatDateTime(
                                                                      job.ExpiryDate
                                                                    )}
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="job-card-actions">
                                                            <Link
                                                              className="job-card-link btn"
                                                              to={
                                                                "/vacancies/vacancy/" +
                                                                new Buffer(
                                                                  job.Id
                                                                ).toString(
                                                                  "base64"
                                                                ) +
                                                                "/" +
                                                                slugify(
                                                                  job.JobTitle
                                                                )
                                                              }
                                                            >
                                                              Learn more
                                                            </Link>
                                                          </div>
                                                        </div>
                                                      </Link>
                                                    </div>
                                                  ))
                                                ) : (
                                                  <div className="job-card card job-card-no-results">
                                                    <div className="job-card-details">
                                                      <div className="no-results">
                                                        Sorry, we don't have any
                                                        vacancies matching your
                                                        search.
                                                      </div>
                                                      <button
                                                        id="reset_vacs"
                                                        className="nav-action-sm"
                                                        onClick={() =>
                                                          resetVacs()
                                                        }
                                                      >
                                                        Reset
                                                      </button>
                                                    </div>
                                                  </div>
                                                )}
                                              </>
                                            ) : (
                                              <div className="no-vacancies">
                                                Loading vacancies...
                                              </div>
                                            )}

                                            <div className="load-more-wrap col-12">
                                              {currentCount < vacsCount && (
                                                <a
                                                  className="nav-action-sm"
                                                  role="button"
                                                  onClick={() => loadMore()}
                                                >
                                                  {loadMoreText}
                                                </a>
                                              )}
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <SmartFeedJobAlerts
                                apiKey={apiKey}
                                gdprKey={gdprKey}
                                group={group}
                                location={true}
                                region={true}
                                category={true}
                                industry={true}
                                jobType={true}
                                jobTime={true}
                                CompanyGroupName={CompanyGroupName}
                                VacancyPageBaseURL={VacancyPageBaseURL}
                                ReturnToSearchURL={ReturnToSearchURL}
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section>
            <div>
              <div className="container">
                <div className="row clearfix">
                  <div className="col-md-12 column">
                    {" "}
                    <div>
                      <div className="stphilips-spacing">
                        <article aria-label="Subscribe for job alerts">
                          <div className="ctacard soft">
                            <div className="ctacard__body d-flex justify-content-center">
                              <div className="col-md-10 col-lg-6 text-center">
                                <h4 className="ctacard__body__title">
                                  <span>
                                    Can't find a job role to suit you?
                                  </span>
                                </h4>
                                <p className="ctacard__body__summary">
                                  <span>
                                    Get job alerts straight to your inbox and be
                                    the first to see our new vacancies.
                                  </span>
                                </p>
                                <Link to="/job-alerts/" className="btn--action">
                                  Subscribe for job alerts
                                </Link>
                              </div>
                            </div>
                          </div>
                        </article>
                      </div>{" "}
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <div className="page-loading-block"></div>
      )}
    </div>
  )
}

export default SmartFeedFiltersComponent
