import React, { useState, useEffect } from "react"
import { RouteComponentProps } from "@reach/router"

import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import fetchJsonp from "fetch-jsonp"
require("es6-promise").polyfill()

const smartfeedNotFound = ({ id = 1 }) => {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <main id="main">
        <div className="page__header-container page__header-container--four-o-four-landing">
          <div className="container">
            <div
              className="row justify-content-center page__header four-o-four-landing__header page__header--four-o-four-landing"
              id="listing-top"
            >
              <div className="notfound">
                <section className="app-content">
                  <div className="app-content-content">
                    <div className="container">
                      <h1>Sorry, we couldn't find that page.</h1>
                      <a
                        href="/"
                        className="btn btn-primary button-primary-hero"
                      >
                        Go back
                      </a>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default smartfeedNotFound
