import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"

import SmartFeedFiltersComponent from "../components/SmartFeedWithFilters_WT"

require("es6-promise").polyfill()

const getURL = function getURL() {
  return typeof window !== "undefined" ? window.location.href : ""
}

export default function VacanciesListPage({ props }) {
  const [vacancyLoading, setVacancyLoading] = useState(true)

  return (
    <Layout {...props}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Careers at the St Philips Care</title>
        <meta
          name="description"
          content="Find your new role at St Philips Care today"
        />
      </Helmet>

      <SmartFeedFiltersComponent
        setVacancyLoading={setVacancyLoading}
        vacancyLoading={vacancyLoading}
        apiKey={"I8IwKRQjh-rCGaCJNwdx-fgER4cqd5syCxp4pTyZ1WI"}
        group={false}
        gdprKey=""
        CompanyGroupName="St Philips Care"
        VacancyPageBaseURL="https://www.stphilipscarerecruitment.co.uk/vacancies/vacancy/"
        ReturnToSearchURL="https://www.stphilipscarerecruitment.co.uk/vacancies/"
        contentDataData={{}}
      />
    </Layout>
  )
}
